import { Route, Routes } from 'react-router-dom'
import RequireAuth from '@auth-kit/react-router/RequireAuth';
import './App.css';

// Pages
import HomePage from './pages/HomePage';
import WelcomeAuthPage from './pages/WelcomeAuthPage';

function App() {  
  return (
    <>
      <div className="App">
        <Routes>
          {/* Primary Page Routes */}
          <Route index path="/login" element={<WelcomeAuthPage />} />
          <Route path="/" element={ // Main Home page is hidden behind auth - if not authed, redirect to login
            <RequireAuth fallbackPath='/login'> 
                {/* We can install v1/v2 here as well (and maybe use the same auth?) */}
                <HomePage />
            </RequireAuth>
          } />
        </Routes>
      </div>
    </>
    
  );
}

export default App;
