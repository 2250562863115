/* Utility functions used across the app */
import { Framework } from "./types";

// Function to access the framework object and cast the returned value as a string for TS
// Rather than embed the context here as well, we just pass the actual Framework context in itself
export const accessFramework = (framework: Framework, key: string) => {
    if (!framework) return ''; // This is just for TS. It should never really fire, since this function never runs until framework is defined.
    const value = framework[key as keyof typeof framework] as string;
    return value;
};


/* 
Formatter for user input which embeds it inside <p></p> tags 
Supposedly this nudges OpenAI to contextualize the returned output as HTML, rather than as plain text.
*/
export const formatUserInput = (input: string) => {
    return `<p>${input}</p>`;
}

/* 
Collator & Collector for modifying our localStorage object
This allows us to track the user's conversation history across sessions and page reloads
*/
export const updateLocalStorage = (key: string, value: any) => { // Using any here since this applies for every 
    localStorage.setItem(key, JSON.stringify(value));
};

/* Get a formatted value from localStorage */
export const getLocalStorage = (key: string) => {
    const value = localStorage.getItem(key);
    return value ? JSON.parse(value) : null;
}