/* Hooks */
import { useEffect, useContext } from "react";

/* Types */
import { ChatItem as ChatItemType } from "../lib/types"

/* Context & Framework Utils */
import { FrameworkContext } from "../pages/HomePage";
import { accessFramework } from "../lib/utils";

/* Styles */
import styles from './ChatItem.module.css';

/* Declare the DOMPurifier up here - this lets us validate the HTML we get out of OpenAI */
const DOMPurify = require('dompurify');
const sanitizer = DOMPurify.sanitize; 

export default function ChatItem({index, ...chatItem}: ChatItemType) {
    const userTextState = chatItem.user === 'You' ? styles['you-user'] : styles['senex-user'];
    const framework = useContext(FrameworkContext);

    // Converting back & forth from JSON to strings will lose DateTime formatting - we do this in order to ensure that it's there
    const timestampAsDateTime = (typeof chatItem.timestamp === 'string' ? new Date(chatItem.timestamp): chatItem.timestamp);

    /* 
    Update 5/15: Moved this operation to a React hook to allow us to add target="_blank" to all links in the HTML

    2 steps:
        1. Synthetically parse & modify HTML to add target="_blank" to all links
        2. Sanitize HTML and then inject it into the DOM

    Previously I had to use dangerouslySetInnerHTML, but the linter doesn't seem to hate this as much as I remember
    */
    useEffect(() => {
        let chatMessageString = chatItem.content as string;

        // Sanitize the HTML in the off-chance OpenAI sends us something malicious
        // This is still a fallible operation - as long as we see the results in the DOM we'll catch any issues
        let chatMessageSanitized;
        try {
            chatMessageSanitized = sanitizer(chatMessageString);
        } catch {
            chatMessageSanitized = `<${accessFramework(framework, 'html_parse_failed')}>`;
        }

        let messageDiv = document.getElementById(`${timestampAsDateTime?.getTime() || ""}_${index}`);
        if (messageDiv) {
            // Set the innerHTML
            messageDiv.innerHTML = chatMessageSanitized;

            // Add target="_blank" to all links within the message div
            let urls = messageDiv.querySelectorAll('a');
            if (urls.length > 0) {
                urls.forEach((url) => {
                    url.setAttribute('target', '_blank');
                })
            }
        }
    })

    return (
        <div className={styles["chat-item"]}>
            {/* "You" or "Senex" */}
            <div className={styles['chat-item-user-sec']}>
                <span className={userTextState}>{chatItem.user}</span>
            </div>
            {/* Remaining Data */}
            <div className={styles["chat-item-data-sec"]}>
                {/* Top Metadata, i.e. Timestamp & Assistant Selected */}
                <div className={styles["chat-item-metadata-top-sec"]}>
                    <span className={styles['chat-item-metadata-item']}>{timestampAsDateTime?.toLocaleString()}</span>
                    <span className={styles['chat-item-metadata-item']}>•</span>
                    <span style={{fontWeight: 'bold'}} className={styles['chat-item-metadata-item']}>{chatItem.assistantName || ''}</span>
                </div>
                {/* Core Message Content */}
                <div className={styles["chat-item-core-content"]}>
                    {/* Message-type Items */}
                    {chatItem.type === 'message' && <div id={`${timestampAsDateTime?.getTime()}_${index}`} />}
                    {/* Assistant Changing Items */}
                    {chatItem.type === 'assistantChange' &&
                        <div>
                            <span className={styles["chat-item-assistant-change-text"]}>
                                {`<${accessFramework(framework, 'assistant_change_msg')} ${chatItem.assistantName || ''}>`}
                            </span>
                        </div>}
                </div>
            </div>
        </div>
    )
}