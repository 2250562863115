/* Hooks */
import { useEffect, useState, createContext } from 'react';

/* Components */
import senexBanner from '../senex_ai_banner_image.png'
import Login from '../components/Login';
import FullPageLoader from '../components/FullPageLoader';

/* Utils */
import { accessFramework } from '../lib/utils';

/* Types */
import { Framework } from '../lib/types';

/* Queries */
import { GetFilledFrameworkById } from '../lib/graphql/queries';

/* Styles */
import styles from './WelcomeAuthPage.module.css';
import masterStyles from '../MasterStyles.module.css'

// Create our Framework Context for use in App components
export const FrameworkContext = createContext<Framework>(null);

export default function WelcomeAuthPage() {
    const [framework, setFramework] = useState<Framework>(null);

    useEffect(() => {
        const frameworkId = '2'; // This is the default login framework - this ID would need to be changed if that object is deleted
        // This retrieves our framework from the database filled with text strings
        GetFilledFrameworkById(frameworkId).then((response) => {
            if (response) {
                setFramework(response.filledFrameworkById);
            }
        });
    },[]); // Only run this once on page load

    return (
        <FrameworkContext.Provider value={framework}>
            <div className={`${styles['welcome-page-full']} ${masterStyles['fade-in-2s']}`}>
                <div className={styles["welcome-page-content"]}>
                    {!framework ? (<FullPageLoader />):(
                        <>
                            <img src={senexBanner} className={styles['senex-logo-large']} alt="logo" />
                            <p className={styles['header-text']}>{accessFramework(framework, 'senex_welcome_banner')}</p>
                            <p className={styles['secondary-text']}>{accessFramework(framework, 'senex_welcome_description')}</p>
                            <Login />
                        </>
                    )}
                </div>
            </div>
        </FrameworkContext.Provider>
    )
}