/* Hooks */
import { useContext } from "react";

/* Types */
import { ChatHistory as ChatHistoryType,  } from "../lib/types"

/* Components */
import ChatItem from "./ChatItem";

/* Context & Framework Utils */
import { FrameworkContext } from "../pages/HomePage";
import { accessFramework } from "../lib/utils";

/* Styles */
import styles from './ChatHistory.module.css';

interface ChatHistoryProps {
    assistants: { [key: string]: string }
    chatHistory: ChatHistoryType
}

export default function ChatHistory({ chatHistory, assistants }: ChatHistoryProps) {
    const framework = useContext(FrameworkContext);

    return (
        <div className={styles["chat-history-space"]}>
            {chatHistory.map((chatItem, index) => {
                return (
                    <ChatItem 
                        key={index} {...chatItem} 
                        index={index} 
                        assistantName={accessFramework(framework, assistants[chatItem.assistantId || 'D'])} 
                    />
                )
            })}
        </div>
    )
}