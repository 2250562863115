/* Hooks */
import { useContext } from 'react';

/* Components */
import { SolidButton } from './Buttons';

/* Context & Framework Utils */
import { FrameworkContext } from "../pages/HomePage";
import { accessFramework } from "../lib/utils";

/* Styles */
import styles from './AssistantSelector.module.css';

interface AssistantSelectorProps {
    assistants: { [key: string]: string },
    onSelectAssistant: (assistantId: string) => void,
    selectedAssistant: string,
    loading?: boolean
}

export default function AssistantSelector({ assistants, onSelectAssistant, selectedAssistant, loading }: AssistantSelectorProps) {
    const framework = useContext(FrameworkContext);

    const onClickSelectAssistant = (newAssistantId: string) => {
        onSelectAssistant(newAssistantId);
    }
    return (
        <div className={styles['assistant-selector-space']}>
            {assistants && Object.entries(assistants).map((assistant) => {
                const assistantId = assistant[0];
                const assistantName = accessFramework(framework, assistant[1]);

                return (
                    <SolidButton 
                        key={assistantId}
                        disabled={loading}
                        content={assistantName} 
                        addedStyles={selectedAssistant === assistantId ? (styles['selected']) : ''}
                        onClick={() => onClickSelectAssistant(assistantId)} 
                    />
                )
            })}
        </div>
    )
}