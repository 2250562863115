/* Hooks */
import { useEffect, useState, createContext } from 'react';
import useAuthUser from 'react-auth-kit/hooks/useAuthUser'
import useSignOut from 'react-auth-kit/hooks/useSignOut';
import { useNavigate } from 'react-router-dom';

/* Images */
import senexBanner from '../senex_ai_banner_image.png'

/* Queries */
import { 
    GetFilledFrameworkById, 
    InitializeThread, 
    OpenAiResponse 
} from '../lib/graphql/queries';

/* Utils */
import { 
    accessFramework, 
    formatUserInput, 
    updateLocalStorage, 
    getLocalStorage 
} from '../lib/utils';

/* Components */
import FullPageLoader from '../components/FullPageLoader';
import { HamburgerMenuButton } from '../components/Buttons';
import ChatWindow from '../components/ChatWindow';
import ChatHistory  from '../components/ChatHistory';
import AssistantSelector from '../components/AssistantSelector';
import MenuDropdown from '../components/MenuDropdown';

/* Types */
import {
    ChatHistory as ChatHistoryType, 
    ChatItem, 
    SessionData, 
    AuthUserData, 
    Framework,
    OpenAIParams
} from '../lib/types';

/* Styles */
import styles from './HomePage.module.css';
import masterStyles from '../MasterStyles.module.css'

// Define a reference for our assistants to be used in the API and for the state variable
// The keys here correspond w/ session['context'] in the legacy Python code
// Update 3/21: We can store the assistant ID to button name (assistant name, really) here in one spot, then send it throughout the app
const assistants:{ [key: string]: string } = {
    'D': 'b1_txt',
    'E': 'b2_txt',
    'A': 'b3_txt',
}

// Create our Framework Context for use in App components
export const FrameworkContext = createContext<Framework>(null);

export default function HomePage() {
    // Pull in the stored data for our authenticated user
    const authUserData = useAuthUser() as AuthUserData;
    const signOut = useSignOut();
    const navigate = useNavigate();

    /* States - can probably move some of these to useRef but the calls are slow enough that performance & re-renders aren't a huge concern */

    // Conditions for UI
    const [responsePending, setResponsePending] = useState(false); // Tracks whether we're waiting for a response from OpenAI
    const [submitErrorMsg, setSubmitErrorMsg] = useState<string | null>(null); // Error message for form submission
    const [framework, setFramework] = useState<Framework>(null);
    const [menuExpanded, setMenuExpanded] = useState(false); // Tracks whether the hamburger menu is expanded

    // OpenAI Thread & Session Data
    const [threadId, setThreadId] = useState<string | null>(getLocalStorage('threadId') || null); // This will be used to track our conversation thread with OpenAI
    const [assistantId, setAssistantId] = useState(getLocalStorage('assistantId') || 'D');
    const [belief, setBelief] = useState<string | null>(getLocalStorage('belief') || null); // This will be used to track the user's belief text, vs. the one from the session
    const [chatHistory, setChatHistory] = useState<ChatHistoryType>(getLocalStorage('chatHistory') || []);
    const [sessionData, setSessionData] = useState<SessionData>(getLocalStorage('sessionData') || null); // The SessionData type allows for either a JSON object or a null field

    /* 
    Local Storage updates - can just use separate useEffects for all of these. 
    It looks like it'd be a cluttered, unperformant rerendering nightmare but it's exactly as performant as tying them all into one 
    */
    useEffect(() => { updateLocalStorage('threadId', threadId) }, [threadId])
    useEffect(() => { updateLocalStorage('assistantId', assistantId) }, [assistantId])
    useEffect(() => { updateLocalStorage('belief', belief) }, [belief])
    useEffect(() => { updateLocalStorage('chatHistory', chatHistory) }, [chatHistory])
    useEffect(() => { updateLocalStorage('sessionData', sessionData) }, [sessionData])

    /* 
    Note(s) for Dad: 
    useEffect is a React hook which runs when the component is mounted in the DOM, and then runs again whenever its dependencies change (in this case, just authUserData).
    As such, this particular instance of useEffect only runs once, since the user's auth data won't change until they're automatically signed out after an hour.
    You can have as many instances of useEffect on a component as you want (some Amazon components had 5+), though they can cause performance issues if they're re-firing all the time.
    This is typically where we put data calls that the page needs to load right away, i.e. the text string framework.
    */
    useEffect(() => {
        if (!authUserData) navigate('/login') // Redirect back to the login page if the user has been logged out at some point
        
        const frameworkId = authUserData.FrameworkId || '1'; // Default to the first framework if we the user doesn't have one
        // Retrieves our framework from the database filled with text strings
        GetFilledFrameworkById(frameworkId).then((response) => { // This is promise syntax - once the function returns, the block inside of .then will fire
            if (response) setFramework(response.filledFrameworkById);
        });
    }, [authUserData, navigate]); // This is the dependency array - if any of these change, the useEffect will fire again (hence the "reactivity" of React)

    // Reset the error message after 2 seconds
    useEffect(() => {
        setTimeout(() => {
            setSubmitErrorMsg(null);
        }, 2000)
    },[submitErrorMsg])

    // Push a new item to the chat history blob. This can be either an actual message, or just a change in assistant or update
    const addToChatHistory = (chatItem: ChatItem) => {
        let newChatHistory = chatHistory;
        const timestamp = new Date()
        newChatHistory.push({ ...chatItem, timestamp });
        setChatHistory([...newChatHistory]); // Spread operator here serves to force a re-render on chatHistory and bypass JS's shallow comparison
    }

    // Create a new OpenAI thread - broke this function out so that we could view it separate from the rest of the OpenAI logic
    const createNewThread = async (userInput: string, ) => {
        // By doing it this way instead of w/ Promises, we can return the new threadId value immediately instead of needing to wait w/ a useEffect Hook
        const response = await InitializeThread(userInput, authUserData.id); 
        const threadId = response.initializeThread.threadId;
        setThreadId(threadId);
        return threadId
    }

    /* 
    UPDATE 3/12
    Made the decision to split these functions up for more clarity. The call process cascades down from which method the user starts in.

    The main "head-ins" for accessing OpenAI come from either having valid text and clicking submit, OR from hitting the button for a new assistant.
    As such, the best way to do this is to bifurcate our calls and send to OpenAI based on these respective methods.

    There are ultimately 6 types of submission one can make here: 
        1. Discuss Belief (D), no text entered (initial state OR when a user taps the button while another is selected)
        2. Explore the Evidence (E), no text entered (when a user taps the button while another is selected)
        3. Understand Alternative Views (A), no text entered (when a user taps the button while another is selected)
        4. Discuss Belief (D), text entered
        5. Explore the Evidence (E), text entered
        6. Understand Alternative Views (A), text entered

    We can then boil this down into 2 head-ins, as mentioned:
        - When the user taps submit/enter with text entered, they are directed to onClickChatSubmit.
        - When the uer taps the button for a new assistant, they are directed to onClickChangeAssistant.
    In both of these cases, assistantId will ALWAYS be defined. It starts as "D" and is never nullable throughout.
    */

    // Callback from Clicking on an Assistant button. Note that this fires even if the user selects the current assistant.
    const onClickChangeAssistant = (newAssistantId: string) => {
        setResponsePending(true); // Go into our loading state - we'll revert it when the operation resolves

        // const newAssistantId = assistantIdClicked; // The NEW assistant we're switching to
        if (newAssistantId === assistantId) {
            setResponsePending(false); 
            return // Kill right away if it's the same assistant
        } else {
            setAssistantId(newAssistantId);
        }

        // Define the chat history object and push it to the chat history state
        addToChatHistory({
            user: 'You', 
            type: 'assistantChange',
            content: `Switched to ${accessFramework(framework, assistants[newAssistantId])}.`,
            assistantId: newAssistantId
        });

        // Assemble our params for submission to OpenAI
        const openAiParams: OpenAIParams = {
            framework: framework, 
            threadId: threadId, // We can guarantee that ThreadID is defined by the time we get here
            userInput: null, // User Input is Null in this case
            assistantId: newAssistantId, 
            sessionData: sessionData // This may be null, but we'll set it after the first call
        }
        callOpenAiResponse(openAiParams);
    }

    // Callback from ever hitting the enter button in the chat window. Note that this fires even if the user submits with no text.
    const onClickChatSubmit = async () => {
        setResponsePending(true); // Go into our loading state - we'll revert it when the operation resolves
        let userInput = (document.getElementById('textEntry') as HTMLTextAreaElement).value; // Extract our entered belief text
        
        // Alert for an empty user input - this may be valid but won't allow it now
        if (!userInput || userInput === '') {
            alert('Please enter a belief before submitting.');
            setResponsePending(false);
            return
        }

        const userInputRaw = userInput; // Store the raw user input for the chat history
        userInput = formatUserInput(userInput); // Format the user input for OpenAI (embed in <p></p> tags)

        // Generate a new thread if we don't currently have one - this will only fire on the first submission, thus we only need it here
        let sharedThreadId = threadId; // Set another variable here, since for new threads the state update won't fire at the same time
        if (!threadId) {
            // We need the thread ID in this function, so we have to await the response from a helper function instead of the promise method
            sharedThreadId = await createNewThread(userInput);
            setBelief(userInputRaw); // Set the belief text in the state - this is only for display purposes
        }

        // Define the chat history object and push it to the chat history state
        addToChatHistory({
            user: 'You', 
            type: 'message',
            content: userInputRaw,
            assistantId: assistantId
        });

        // Assemble our params for submission to OpenAI
        const openAiParams: OpenAIParams = {
            framework: framework, 
            threadId: sharedThreadId, // We can guarantee that ThreadID is defined by the time we get here
            userInput: userInput, // User Input is the user's belief text
            assistantId: assistantId, 
            sessionData: sessionData // This may be null, but we'll set it after the first call
        }
        callOpenAiResponse(openAiParams);
    }

    // Submit the initial belief to OpenAI
    const callOpenAiResponse = async (openAiParams: OpenAIParams) => {        
        // Finally send to OpenAI w/ our openAIParams object
        OpenAiResponse(
            openAiParams.framework, 
            openAiParams.threadId, 
            openAiParams.userInput,
            openAiParams.assistantId, 
            openAiParams.sessionData
        ).then((response) => {
            if (response) {
                // Timeout case
                if (response.timedOut) {
                    setSubmitErrorMsg(accessFramework(framework, "timeout_msg"));
                    setResponsePending(false);
                    return
                }

                // Case wherein we get an unsuccessful response back from OpenAI
                // Use this as a catch-all for any errors that come back from OpenAI for the moment - change this later on
                if (!response.openAiResponse.success) {
                    // Setting console logs here in the event that we get failures. This is really just for debugging.
                    console.log('OpenAI Error:');
                    console.log(response.openAiResponse);
                    setSubmitErrorMsg(accessFramework(framework, "try_again_msg"));
                    setResponsePending(false);
                    return
                }

                // Clear the text entry space
                (document.getElementById('textEntry') as HTMLTextAreaElement).value=''; 

                // Add the Senex item to our Chat History
                addToChatHistory({
                    user: 'Senex', 
                    type: 'message',
                    content: response.openAiResponse.message,
                    assistantId: openAiParams.assistantId
                })

                // Update our SessionData w/ new information
                if (response.openAiResponse.sessionData) {
                    let newSessionData = response.openAiResponse.sessionData;

                    // Update the visit flags if the user is visiting the new assistants
                    if (openAiParams.assistantId === 'E') newSessionData.hasVisitedEe = true;
                    if (openAiParams.assistantId === 'A') newSessionData.hasVisitedEe = true;
                    
                    setSessionData(newSessionData);
                    setResponsePending(false);
                } 
            } else {
                // Ensure we always re-enable the UI
                setSubmitErrorMsg(accessFramework(framework, "try_again_msg"));
                setResponsePending(false);
            }
        });
    }

    // Version of the OpenAI call which uses the streaming response
    const callOpenAiResponseStreaming = async (openAiParams: OpenAIParams) => {

    } 

    // Confirmation of User signout
    const onClickSignout = () => { 
        if (window.confirm(accessFramework(framework, "quit_senex_msg"))) {
            // Sign out the user & redirect to the login page
            signOut();
            localStorage.clear(); // Also clearing localStorage here so we don't have to worry about data between user sign-ins
            navigate('/login');
        }
    }

    // Confirmation of Session Reset
    const onClickResetSession = () => {
        if (window.confirm(accessFramework(framework, "reset_senex_msg"))) {
            localStorage.clear(); 
            window.location.reload();
        }
    }

    // Hamburger Stack button onClick
    const onClickHamburgerBtn = () => {
        let exp = menuExpanded;
        setMenuExpanded(!exp);
    }

    return authUserData ? (
        <FrameworkContext.Provider value={framework}>
            {!framework ? <FullPageLoader />:(
                <div className={styles['page-full-body']}>
                    <div className={`${styles['page-elements-space']} ${masterStyles['fade-in-2s']}`}>
                        {/* Header */}
                        <div className={styles['page-header']}>
                            <img src={senexBanner} className={`${styles['senex-logo']} ${styles['logo-size']}`} alt="logo" />
                            <div className={styles['user-options-space']}>
                                <span>{authUserData.firstname ? `${accessFramework(framework, "header_user_greeting")} ${authUserData.firstname}`:'Welcome to Senex'}.</span>
                                <HamburgerMenuButton disabled={responsePending} onClick={() => onClickHamburgerBtn()} />
                            </div>
                            {/* Hamburger Dropdown Content */}
                            {menuExpanded && 
                                <MenuDropdown 
                                    onClickSignOut={onClickSignout}
                                    onClickReset={onClickResetSession}
                                    disabled={responsePending} 
                                />}
                        </div>
                        {/* Page Content */}
                        <div className={styles['page-content']}>
                            <h1>{accessFramework(framework, 'title_1')}</h1>
                            {sessionData && 
                                <ChatHistory 
                                    assistants={assistants} 
                                    chatHistory={chatHistory} 
                                />}
                            {!sessionData && <p style={{ textAlign: 'left'}}>{accessFramework(framework, 'intro_msg')}</p>}
                            {submitErrorMsg && <p className='submit-error-message'>{submitErrorMsg}</p>}
                            {sessionData &&
                                <div className={styles['session-data-full-space']}>
                                    <div className={styles['session-metadata-space']}>
                                        <span className={styles['opt-label']}>{accessFramework(framework, 'belief_msg_0')}</span>
                                        <span>{belief}</span>
                                    </div>
                                    <div className={styles['session-metadata-space']}>
                                        <span className={styles['opt-label']}>{accessFramework(framework, 'active_button_txt')}</span>
                                        <span>{accessFramework(framework, assistants[assistantId])}</span>
                                    </div>
                                    <AssistantSelector 
                                        selectedAssistant={assistantId} 
                                        onSelectAssistant={(newAssistantId) => onClickChangeAssistant(newAssistantId)}
                                        assistants={assistants}
                                        loading={responsePending}
                                    />
                                </div>}
                            <ChatWindow 
                                textAreaId={'textEntry'}
                                onSubmit={onClickChatSubmit} 
                                loading={responsePending}
                                chatPlaceholder={accessFramework(framework, "text_submit_box_placeholder")}
                            />
                        </div>
                    </div>
                </div>
            )}
        </FrameworkContext.Provider>
    ):(<>
        {/* Redirect instantly if the user has been idling and is logged out before they can click the signout button */}
        {navigate('/login')}
    </>)
}