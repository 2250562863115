// All GraphQL requests we'll plan to make from the frontend
import { GraphQLClient, gql } from 'graphql-request';

// Client will point towards the local IP of either the test environment or the server
// We'll alternate this based on the dev or production run mode
const devUrl = 'http://127.0.0.1:9000/graphql';
const prodUrl = 'https://senex.ai/graphql';
const graphqlUrl = process.env.NODE_ENV === 'production' ? prodUrl:devUrl
const client = new GraphQLClient(graphqlUrl);

export async function LoginUser(email, password) {
    const query = gql`
        query LoginUser($email: String!, $password: String!) {
            login(email: $email, password: $password) {
                message
                token
                success
                user {
                    id
                    email
                    firstname
                    lastname
                    hasAccess
                    FrameworkId
                }
            }
        }
    `;
    return await client.request(query, { email, password });
}

export async function GetFilledFrameworkById(id) {
    const query = gql`
        query GetFilledFrameworkById($id: String!) {
            filledFrameworkById(id: $id)
        }
    `;
    return await client.request(query, { id });
}

/* MUTATIONS */

export async function RequestAccess(email) {
    const mutation = gql`
        mutation RequestAccess($email: String!) {
            requestAccess(email: $email) {
                message
                success
            }
        }
    `;
    return await client.request(mutation, { email });
}

export async function InitializeThread(userInput, userId) {
    const mutation = gql`
        mutation InitializeThread($userInput: String!, $userId: String!) {
            initializeThread(userInput: $userInput, userId: $userId) {
                success
                promptId
                threadId
            }
        }
    `;
    return await client.request(mutation, { userInput, userId });
}

export async function OpenAiResponse(framework, threadId, userInput, assistantId, sessionData) {
    const abortController = new AbortController(); // Define AbortController here, vs globally, so that we can call it on a request vs. client basis
    const mutation = gql`
        mutation OpenAiResponse($framework: JSON, $threadId: String, $userInput: String, $assistantId: String, $sessionData: JSON) {
            openAiResponse(framework: $framework, threadId: $threadId, userInput: $userInput, assistantId: $assistantId, sessionData: $sessionData) {
                success
                message
                sessionData
            }
        }
    `;
    // Setting a 45s timeout for the OpenAI response
    let isTimedOut = false;
    let timeout = setTimeout(() => {
        console.log('ERROR: Request timed out');
        isTimedOut = true;
        abortController.abort();
    }, 45000); // 45000ms = 45s timeout

    // Nest the request and timeouts in a try/catch block to catch the error from the AbortController
    try {
        // Run our actual request
        const variables = { framework, threadId, userInput, assistantId, sessionData };

        /* 
        Note here that we send the signal in the request instead of in the client object itself
        This is critical or the entire client object will abort until you refresh the page
        */
        const response = await client.request({
            document: mutation, 
            variables,
            signal: abortController.signal
        });
        if (response) { // This if statement is essentially superfluous; the catch condition would occur if the response isn't returned
            clearTimeout(timeout); // Clear the timeout (both cases)
            return response;
        }
    } catch (error) {
        console.log(error) // Note to self: clean up the fucking console errors here. Shit just fails and then you have no idea what happened.
        clearTimeout(timeout); // Clear the timeout
        let errorMessage = error.message;
        if (isTimedOut) {
            errorMessage = 'Timeout hit';
        }
        return { success: false, message: errorMessage, timedOut: true};
    }
}