/* Hooks */
import { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import useSignIn from 'react-auth-kit/hooks/useSignIn';

/* Components */
import { TextButton, SolidButton } from './Buttons';

/* Queries */
import { LoginUser, RequestAccess } from '../lib/graphql/queries';

/* Styles */
import styles from './Login.module.css';
import masterStyles from '../MasterStyles.module.css';

/* Context & Framework Utils */
import { FrameworkContext } from '../pages/WelcomeAuthPage';
import { accessFramework } from '../lib/utils';

export default function Login() {
    const framework = useContext(FrameworkContext);
    const [isLogin, setIsLogin] = useState(true);
    const [submitted, setSubmitted] = useState(false);
    const [submitErrorMsg, setSubmitErrorMsg] = useState<string | null>(null);
    const [requestAccessSuccess, setRequestAccessSuccess] = useState<boolean>(false);
    const navigate = useNavigate();
    const signIn = useSignIn();

    // Reset the error message if we switch the login/request mode
    useEffect(() => {
        setSubmitErrorMsg(null);
    }, [isLogin])

    // Resets the error message after 2.5 seconds. I find this is a more appealing UX than having the message linger
    useEffect(() => {
        if (submitErrorMsg) {
            setTimeout(() => {
                setSubmitErrorMsg(null);
            }, 2000)
        }
    }, [submitErrorMsg])

    // Redirects the user back to the home screen if they request access
    useEffect(() => {
        if (requestAccessSuccess) {
            setTimeout(() => {
                navigate("/", { replace: true });
            }, 3000)
        }
    }, [requestAccessSuccess, navigate])
    
    /* Login function called when we hit submit - has several layers of checks */
    const onSubmitLogin = () => {
        setSubmitted(true);
        const email = (document.getElementById('loginEmail') as HTMLInputElement).value;
        const password = (document.getElementById('loginPassword') as HTMLInputElement).value;

        // Early validation checks
        if (!email || !password) {
            setSubmitErrorMsg(accessFramework(framework, "login_err_missing_info"))
            setSubmitted(false);
            return
        }

        // GQL call which validates the user's login credentials and signs them in with a web token
        LoginUser(email, password).then((response) => {
            setSubmitErrorMsg(null);

            // Initial Checks
            if (!response || !response.login) {
                setSubmitErrorMsg(accessFramework(framework, "login_err_server_err")) 
                return
            } 

            // Successful Login WITH a valid token
            if (response.login.success === true && response.login.token) {
                setSubmitted(false);
                // User is real, but has no access
                if (!response.login.user.hasAccess) {
                    setSubmitErrorMsg(accessFramework(framework, "login_err_no_access"))
                    return
                }
                // Gets our signed JWT and puts this in cookies, thus signing the user in
                const signedInWithToken = signIn({
                    auth: {
                        token: response.login.token,
                        type: 'Bearer',
                    },
                    userState: {
                        ...response.login.user
                    }
                });
                
                if (signedInWithToken) {
                    // Redirect to the main home page if we get a valid sign-in & it is stored in cookies
                    navigate("/", { replace: true });
                } else {
                    setSubmitErrorMsg(accessFramework(framework, "login_err_token_failed"))
                }
            // Unsuccessful login attempt
            } else {
                const message = response.login.message; // Extract the response message
                
                // All Cases
                if (message === 'Email not found') setSubmitErrorMsg(accessFramework(framework, "login_err_user_email_not_found"))
                else if (message === 'No access') setSubmitErrorMsg(accessFramework(framework, "login_err_no_access"));
                else if (message === 'Password incorrect') setSubmitErrorMsg(accessFramework(framework, "login_err_password_incorrect"));
                else if (!response.login.token) setSubmitErrorMsg(accessFramework(framework, "login_err_token_failed"))
                else setSubmitErrorMsg(accessFramework(framework, "login_err_server_err"))

                // Reset the submitted state every time regardless
                setSubmitted(false);
            }
        })
    }

    /* Function called when users request access */
    const onSubmitRequestAccess = () => {
        setSubmitted(true);
        const email = (document.getElementById('requestEmail') as HTMLInputElement).value;

        // Early validation checks
        if (!email) {
            setSubmitErrorMsg(accessFramework(framework, "request_err_invalid_email"))
            setSubmitted(false);
            return
        }

        // GQL call which creates the user's request for access (i.e. backend account)
        RequestAccess(email).then((response) => {
            setSubmitErrorMsg(null);

            // Initial Checks
            if (!response) {
                setSubmitErrorMsg(accessFramework(framework, "login_err_server_err")) 
                return
            } else {
                const message = response.requestAccess.message; // Extract the response message

                // User successfully requested access and account is created
                if (response.requestAccess.success) {
                    setRequestAccessSuccess(true);
                // Unsuccessful creation
                } else {
                    if (message === 'User already exists') {
                        setSubmitErrorMsg(accessFramework(framework, "request_err_user_exists"))
                    } else {
                        setSubmitErrorMsg(accessFramework(framework, "login_err_server_err"))
                    }
                    // Reset the submitted state every time regardless
                    setSubmitted(false);
                }
            }
        })
    }

    return (
        <>
            <div className={`${styles['login-component']} ${masterStyles['fade-in-1s']}`}>
                {isLogin ? (
                    <div className={styles['login-entry-fields']}>
                        <p>{accessFramework(framework, 'login_banner_returning_user')}</p>
                        <input id='loginEmail' type='email' placeholder='Email...' />
                        <input id='loginPassword' type='password' placeholder='Password...' />
                        <SolidButton 
                            disabled={submitted} 
                            content={'Submit'} 
                            onClick={onSubmitLogin} 
                            addedStyles={styles['login-submit-button']}
                        />
                        {/* Error/issue messages after attempted logins */}
                        {submitErrorMsg && <span className={styles['submit-error-message']}>{submitErrorMsg}</span>}
                        <TextButton 
                            disabled={submitted} 
                            content={accessFramework(framework, 'login_swap_button_returning_user')} 
                            onClick={() => setIsLogin(false)} 
                            addedStyles={styles['login-alternate-mode-button']}
                        />
                    </div>
                ):(
                    <div className={styles['login-entry-fields']}>
                        <p>{accessFramework(framework, 'login_banner_request_access')}</p>
                        <input id='requestEmail' type='email' placeholder='Email...' />
                        {!requestAccessSuccess ? 
                            <SolidButton 
                                disabled={submitted} 
                                content={'Submit'} 
                                onClick={onSubmitRequestAccess} 
                                addedStyles={styles['login-submit-button']}
                            />:<p className={styles['account-request-success-msg']}>
                                Access Requested Successfully! Redirecting to home in 3s.
                            </p>}
                        {/* Error/issue messages after attempted logins */}
                        {submitErrorMsg && <span className={styles['submit-error-message']}>{submitErrorMsg}</span>}
                        <TextButton 
                            disabled={submitted} 
                            content={accessFramework(framework, 'login_swap_button_request_access')} 
                            onClick={() => setIsLogin(true)} 
                            addedStyles={styles['login-alternate-mode-button']}
                        />
                    </div>
                )}
            </div>
            <p className={styles['login-cookies-warning']}>
                {accessFramework(framework, 'login_cookies_warning')}
            </p>
        </>
    )
}