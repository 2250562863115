/* Hooks */
import { useContext } from 'react';

/* Styles */
import styles from './MenuDropdown.module.css';
import masterStyles from '../MasterStyles.module.css';

/* Context & Framework Utils */
import { FrameworkContext } from "../pages/HomePage";
import { accessFramework } from "../lib/utils";

/* Components */
import { HamburgerContentButton } from './Buttons';

export default function MenuDropdown({ disabled, onClickSignOut, onClickReset }: { 
    disabled?: boolean, 
    onClickSignOut: () => void, 
    onClickReset: () => void 
}) {
    const framework = useContext(FrameworkContext);
    
    return (
        <div className={`${styles['menu-dropdown-space']} ${styles['dropdown-align']} ${masterStyles['fade-in-1s']}`}>
            {/* Reset */}
            <HamburgerContentButton 
                disabled={disabled}
                content={accessFramework(framework, 'restart_btn')}
                onClick={onClickReset}
            />
            {/* Signout */}
            <HamburgerContentButton 
                disabled={disabled}
                content={accessFramework(framework, 'b4_txt')}
                onClick={onClickSignOut}
            />
        </div>
    )
}