/* Hooks */
import { useState } from 'react';

/* Styles */
import styles from './Buttons.module.css';

/* Icons */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';

interface ButtonProps {
    passedId?: string
    content?: string
    onClick: () => void
    addedStyles?: string // Added styles allows us to merge other classes in situationally
    disabled?: boolean
}

// A Typical Solid Button
export function SolidButton({ passedId, content, onClick, addedStyles, disabled }: ButtonProps) {
    return (
        <button id={passedId} disabled={disabled} className={`${addedStyles} ${styles['solid-button']} `} onClick={onClick}>
            <span>{content}</span>
        </button>
    )
}

// A Transparent Text-Based Button
export function TextButton({ content, onClick, addedStyles, disabled }: ButtonProps) {
    return (
        <button disabled={disabled} className={`${addedStyles} ${styles['text-button']}`} onClick={onClick}>
            <span>{content}</span>
        </button>
    )
}

// A formatted Hamburger menu which sits in the top-right
export function HamburgerMenuButton({ onClick, disabled }: ButtonProps) {
    const [isOpen, setIsOpen] = useState(false);

    const btnOnClick = () => {
        setIsOpen(!isOpen);
        if (onClick) onClick();
    }

    return (
        <button disabled={disabled} className={`${styles['hamburger-menu']} ${isOpen ? styles['open']:''}`} onClick={btnOnClick}>
            <FontAwesomeIcon icon={faBars} />
        </button>
    )
}

// A dropdown button which is a part of a dropdown menu
export function HamburgerContentButton({ onClick, content, disabled }: ButtonProps) {
    return (
        <button disabled={disabled} className={styles['hamburger-content-button']} onClick={onClick}>
            <span>{content}</span>
        </button>
    )
}