/* Images */
import senexBanner from '../senex_ai_banner_image.png';

/* Styles */
import styles from './FullPageLoader.module.css';
import masterStyles from '../MasterStyles.module.css';

// Full Page Loading screen while we wait for server calls to finish
export default function FullPageLoader() {
    return (
        <div className={styles["spinner-space"]}>
            <img src={senexBanner} className={styles['senex-logo-large']} alt="logo" />
            <div className={`${styles["spinner-body"]} ${masterStyles['spin-300ms']}`} />
            <span className={`${styles['spinner-loading-text']} ${masterStyles['fade-in-2s-flicker']}`}>Loading...</span>
        </div>
    )
}