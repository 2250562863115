/* Hooks */
import { useContext } from "react";

/* Components */
import { SolidButton } from "./Buttons"
import SmallLoader from "./SmallLoader";

/* Context & Framework Utils */
import { FrameworkContext } from "../pages/HomePage";
import { accessFramework } from "../lib/utils";

/* Styles */
import styles from './ChatWindow.module.css';

interface ChatWindowProps { 
    onSubmit: () => void
    textAreaId: string
    loading?: boolean
    chatPlaceholder?: string
}

// Chat window & submission button
export default function ChatWindow({ onSubmit, textAreaId, loading, chatPlaceholder }: ChatWindowProps) {
    // If we want to have timed state changes to make this more interesting, we can make these state variables
    const framework = useContext(FrameworkContext);
    const loadingText = accessFramework(framework, "loading_spinner_text_1");
    const loadingTextSecondary = accessFramework(framework, "loading_spinner_text_2");

    // Wrapper for added function prop we pass in (this will handle local state changes)
    const onClickSubmit = () => {
        onSubmit();
    }

    // To avoid excessive callbacks, I have this firing only whenever you "focus" on the textarea, i.e. when you click inside it and the cursor appears
    // It will scroll such that the submit button comes into view, which will typically align with the bottom of the screen
    const onStartTyping = () => {
        document.getElementById('chat-submit-button')?.scrollIntoView({ behavior: 'smooth' });
    }

    return (
        <div className={styles['chat-space']}>
            <textarea 
                disabled={loading} 
                id={textAreaId} 
                className={styles['text-entry-space']}
                name='text-entry-space' 
                placeholder={chatPlaceholder}
                onFocus={(e) => onStartTyping()}
            />
            <SolidButton 
                passedId='chat-submit-button'
                disabled={loading} 
                content='Enter' 
                onClick={onClickSubmit} 
                addedStyles={styles['chat-submit-button']}
            />
            {loading ? 
                <SmallLoader 
                    loadingText={loadingText} 
                    loadingTextSecondary={loadingTextSecondary} 
                    scrollToOnRender={true}
                    smallLoaderId="chat-submitted-loading"
                />:<>
                    <p className={styles["chat-window-openai-disclaimer"]}>
                        {accessFramework(framework, "openai_responses_disclaimer")}
                    </p>
                </>}
        </div>
    )
}