/* Hooks */
import { useEffect } from 'react';

/* Styles */
import styles from './SmallLoader.module.css';
import masterStyles from '../MasterStyles.module.css'

interface SmallLoaderProps {
    loadingText?: string
    loadingTextSecondary?: string
    smallLoaderId?: string
    scrollToOnRender?: boolean
}

export default function SmallLoader({ loadingText, loadingTextSecondary, smallLoaderId, scrollToOnRender }: SmallLoaderProps) {
    // Scroll into view if ID and scrollToOnRender are provided
    // This seems to work on Safari as well as Chrome, though I didn't think Safari supported the smooth scrolling behavior
    useEffect(() => {
        if (smallLoaderId && scrollToOnRender) {
            // console.log(`Scrolling into view of small loader w/ ID: ${smallLoaderId}`);
            document.getElementById(smallLoaderId)?.scrollIntoView({ behavior: 'smooth' });
        }
    }, [scrollToOnRender, smallLoaderId])

    return (
        <div id={smallLoaderId} className={styles["spinner-space-small"]}>
            <div className={`${styles["spinner-body-small"]} ${masterStyles['spin-300ms']}`} />
            <span className={`${styles['spinner-loading-text-small']} ${masterStyles['fade-in-3s-flicker']}`}>
                {loadingText || 'Loading...'}
            </span>
            {loadingTextSecondary && 
                <span className={`${styles['spinner-loading-text-sec-small']} ${masterStyles['fade-in-3s-flicker']}`}>
                    {loadingTextSecondary}
                </span>}
        </div>
    )   
}